<template>
  <section id="pricing-plan">
    <div
      v-for="cate in cates"
      :key="cate.id"
    >
      <!-- title text and switch button -->
      <div class="text-center">
        <h1 class="mt-5">
          {{ cate.cate.name }}
        </h1>
        <p class="mb-2 pb-75">
          {{ cate.cate.info }}
        </p>
        <div
          class="d-flex align-items-center justify-content-center mb-5 pb-50"
        >
          <h6 class="mr-1 mb-0">
            Monthly
          </h6>
          <b-form-checkbox
            id="priceSwitch"
            v-model="status"
            name="price-switch"
            value="annually"
            unchecked-value="monthly"
            switch
            @input="tooglePlan"
          />
          <h6 class="ml-50 mb-0">
            Annually
          </h6>
        </div>
      </div>
      <!--/ title text and switch button -->

      <!-- pricing plan cards -->
      <b-row
        class="pricing-card"
      >
        <b-col
          offset-sm-2
          sm="10"
          md="12"
          offset-lg="2"
          lg="10"
          class="mx-auto"
        >
          <b-row>
            <b-col
              v-for="plan in cate.plans"
              :key="plan.id"
              md="4"
            >
              <b-card
                class="popular"
                align="center"
              >
                <div
                  v-show="plan.id == 3"
                  class="pricing-badge text-right"
                >
                  <b-badge
                    variant="light-primary"
                    pill
                  >
                    Popular
                  </b-badge>
                </div>
                <!-- img -->
                <b-img
                  v-if="plan.id==0"
                  :src="pricing.standardPlan.img"
                  class="mb-1"
                  alt="svg img"
                />
                <!--/ img -->
                <h3>{{ plan.name }}</h3>
                <b-card-text>{{ plan.name }}</b-card-text>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup
                      class="font-medium-1 font-weight-bold text-primary"
                    >$</sup>
                    <span
                      class="
                        pricing-basic-value
                        font-weight-bolder
                        text-primary
                      "
                    >{{
                      monthlyPlanShow
                        ? plan.month_price
                        : (plan.year_price/12).toFixed(2)
                    }}</span>
                    <sub
                      class="
                        text-body
                        font-medium-1 font-weight-bold
                      "
                    >/month</sub>
                  </div>
                  <small
                    v-show="!monthlyPlanShow"
                    class="annual-pricing text-muted"
                  >CNY {{ plan.year_price }} /
                    year</small>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item>
                    {{ plan.info }}
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->

                <!-- buttons -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  @click="order(plan.id)"
                >
                  订购
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!--/ pricing plan cards -->
    </div>

    <!--/ pricing free trial -->

    <!-- pricing faq -->
    <div
      v-if="false"
      class="pricing-faq"
    >
      <h3 class="text-center">
        FAQ's
      </h3>
      <p class="text-center">
        Let us help answer the most common questions.
      </p>
      <b-row class="py-2">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
          <app-collapse
            accordion
            type="margin"
          >
            <app-collapse-item
              v-for="(data, index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing faq -->
  </section>
</template>

<script>
import useHttp from '@/http/useHttp'
import useJwt from '@/auth/jwt/useJwt'
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
      plans: [],
      cates: [],
    }
  },
  created() {
    useJwt.getMe()
    useHttp.getShadowXPlans({}).then(res => {
      this.cates = res.data
      // console.log(this.cates)
    })
  },
  methods: {
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    order(id) {
      this.$router.push({ name: 'shadowx-plan-info', params: { id } })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
